import React, { Component } from 'react';
import '../style/Skills.css'

class Skills extends Component {
    render() {
        return (
            <div>
<div className="container">
<div className="skillsholder">
<h1 className="text-center">Skills</h1>
</div>
        <div className="row">
            <div className="col-md-3 col-6 ">
                <div className="progress blue">
                    <span className="progress-left">
                        <span className="progress-bar"></span>
                    </span>
                    <span className="progress-right">
                        <span className="progress-bar"></span>
                    </span>
                    <div className="progress-value" style={{color:'#000000'}}>HTML</div>
                </div>
            </div>
            <div className="col-md-3 col-6">
                <div className="progress yellow">
                    <span className="progress-left">
                        <span className="progress-bar"></span>
                    </span>
                    <span className="progress-right">
                        <span className="progress-bar"></span>
                    </span>
                    <div className="progress-value" style={{color:'#000000'}}>CSS</div>
                </div>
            </div>
            <div className="col-md-3 col-6">
                <div className="progress pink">
                    <span className="progress-left">
                        <span className="progress-bar"></span>
                    </span>
                    <span className="progress-right">
                        <span className="progress-bar"></span>
                    </span>
                    <div className="progress-value" style={{color:'#000000'}}>Javascript</div>
                </div>
            </div>
            <div className="col-md-3 col-6">
                <div className="progress pink">
                    <span className="progress-left">
                        <span className="progress-bar"></span>
                    </span>
                    <span className="progress-right">
                        <span className="progress-bar"></span>
                    </span>
                    <div className="progress-value" style={{color:'#000000'}}>React</div>
                </div>
            </div>
        </div>
    </div>
            </div>
        );
    }
}

export default Skills;