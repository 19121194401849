import React, { Component } from "react";
import "../style/Main.css";
import space from "../imgs/space.jpg";
import Projects from "./Projects";
import Skills from "./Skills";
import banner from '../imgs/banner.jpg';

class Main extends Component {
  render() {
    return (
      
      <div>
        <div className="welcomeholder">
          <h1 id="welcomme">
            Hi i'm <span id="Sefa">Sefa</span>
            <span id="Safe">Safe</span>
          </h1>
          <h2 className="desc">I develop your dreams into web pages</h2>
          <div class="container-fluid">
          <div class="row">
          <div class="col-12 col-lg-12 col-md-12">
          <img src={banner} alt="banner" className="banner"/>
          </div>
          </div>
          <div className="row inforow">
            <div className="col-lg-4 col-md-4 col-12">
              <h6>
                Contact:
                <a href="mailto:hello@safegozen.com">hello@safegozen.com</a>
              </h6>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <h6>
                Professional Network:
                <a href="https://www.linkedin.com/in/mehmet-sefa-g%C3%B6zen-4519b21bb">
                  Mehmet Sefa Gözen
                </a>
              </h6>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <h6>
                Social Network:{" "}
                <a href="https://www.instagram.com/sefagozen">Sefagozen</a>
              </h6>
            </div>
          </div>
          </div>
         
        
        </div>
        <div className="container-fluid hcontainer">
          <div className="row hrow">
            <div className="col-lg-8 col-md-8 col-12 hcol8f7">
            <h2 className="text-center mt-4">Summary</h2>
              <h4>
                Hi there, my name is Sefa and I'm a 23-year-old web developer
                with two years of experience in the field. I specialize in
                front-end development using technologies like CSS, JavaScript,
                and HTML, and I'm particularly skilled in responsive design. I'm
                excited to share my portfolio with you and hope you enjoy it!
                I've worked on a variety of projects that showcase my skills and
                expertise in web development, and I'm always looking for new
                opportunities to learn and grow in my field. Thank you for
                taking the time to visit my portfolio!
              </h4>
            </div>
            <div className="col-lg-4 col-md-4 col-12  hcol4">
              <div className="picframe">
                <img src={space} alt="blank" id="space"></img>
              </div>
            </div>
          </div>
          <section id="projects">
            <div className="row hrow">
              <div className="col-12 hcolc65">
                <Projects />
              </div>
            </div>
          </section>
          <section id="Skills" style={{backgroundColor:"#8f746d", marginLeft:"-15px",marginRight:"-15px"}}>
            <Skills/>
          </section>
        </div>
      </div>
    );
  }
}

export default Main;
