import React, { Component } from "react";
import "../style/Projects.css";

class Projects extends Component {
  render() {
    return (
      <div className="container projectcontainer mt-4 mb-4">

<h2 className="text-center">Projects</h2>
        <div className="row projectrow">
        
          <div className="col-lg-4 col-md-4 col-12 projectboxes mt-2">
         
            <div className="box col-12 text-center mb-4">
            
              {/* <a href="https://www.assosmoda.com">
                <img src={IMGAssos} alt="textile-website" className="pic" />
              </a> */}
              <h4>
                <a href="https://www.assosmoda.com" style={{color:'#000000'}}>Assos Moda</a>
              </h4>
              <p>
                I recently completed a website project for a textile company
                named Assos Moda. Using ReactJS, HTML, and CSS, I was able to
                build a dynamic, single-page application that provides a
                seamless user experience for visitors to the site. I also
                implemented responsive design, ensuring that the website looks
                and functions correctly on a wide range of devices. Overall, the
                website has been well received by the client and their
                customers, and I am proud to have contributed to the success of
                Assos Moda with this project.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4  col-12 projectboxes mt-2">
            <div className="box col-12 text-center">
              <h4>E-commerce Website</h4>
              <p>
                As part of my graduation project, I chose to build an e-commerce
                website. I used a combination of ReactJS, HTML, CSS, NodeJS,
                MySQL, ExpressJS, and Axios to create a fully functional,
                user-friendly website. I developed both the front-end and
                back-end of the website, allowing me to gain valuable experience
                in web development and database management. The website allows
                customers to browse and purchase products online, and includes
                features such as a shopping cart, liked items, product
                recommendations.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4  col-12 projectboxes mt-2">
            <div className="box col-12 text-center">
              <h4>Deals Page</h4>
              <p>
                As part of my role at the company, I recently created a "deals
                page" that displays products and prices from our database. The
                page includes a filter that allows users to view deals from our
                automative, wood, and metal divisions. Additionally, the page
                includes a search bar that allows users to quickly find specific
                products. I was able to implement these features using a
                combination of front-end, Such as HTML, CSS, and JavaScript for
                the user interface. The page has been well received by our
                customers, and has helped to promote the products and services
                offered by our company.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Projects;
